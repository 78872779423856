import Box from "@mui/material/Box";
import {SnackbarTypes, Styles} from "../../contstants";
import {Autocomplete, Button, FormControl, TextField, Tooltip, Typography} from "@mui/material";
import {DatePicker, DateTimePicker, MobileDateTimePicker} from "@mui/x-date-pickers";
import {
    DataGrid, GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {axiosInstance} from "../../config/interceptors";
import {buildRequestHeaders, formatDate} from "../../utils";
import dayjs from "dayjs";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {hideSpinner} from "../../redux/action-types/app";
import PendingIcon from "@mui/icons-material/Pending";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AlertDialog from "../../components/alert-dialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

export default function EmployeeSearchPage() {
    // Set default payroll schedule
    const currentDay = dayjs();

    let defaultPayStart = currentDay.date(1).hour(0).minute(0).second(0).millisecond(0);
    let defaultPayEnd = currentDay.date(15).hour(0).minute(0).second(0).millisecond(0);

    if (currentDay.date() > 15) {
        defaultPayStart = currentDay.date(16).hour(0).minute(0).second(0).millisecond(0);
        defaultPayEnd = currentDay.date(dayjs().endOf('month').date()).hour(0).minute(0).second(0).millisecond(0);
    }

    const [rowData, setRowData] = useState([]);
    const [autoCompleteData, setAutocompleteData] = useState([]);
    const [periodStart, setPeriodStart] = useState(defaultPayStart);
    const [periodEnd, setPeriodEnd] = useState(defaultPayEnd);
    const [customLog, setCustomLog] = useState({});
    const [selected, setSelected] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [formError, setFormError] = useState({});

    const customLogRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        // getAllRowData();
        getAutocompleteData();

    }, []);

    customLogRef.current = customLog;

    const handleEditClick = (id) => {

    }

    const handleDeleteClick = (id) => {

    }

    const columns = [
        {
            field: 'timelogDate',
            headerName: 'Date',
            headerAlign: 'center',
            align: 'center',
            width: 220,
            valueFormatter: ({value}) => value && formatDate(value, 'MM/DD/YYYY')
        },
        {
            field: 'loggedHours',
            headerName: 'Logged Hours',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            description: 'This is the automated logged duration captured by the system (Time-out - Time-in).'
        },
        {
            field: 'actualHours',
            headerName: 'Actual Hours',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            description: 'This is the time manually selected by the user upon time-out.'
        },
        {field: 'overtimeHours', headerName: 'Overtime', headerAlign: 'center', align: 'center', width: 180},
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            renderCell: (params) => {

                if (params.value === "PENDING") {
                    return (
                        <Tooltip title="Pending">
                            <PendingIcon
                                sx={{
                                    color: '#e1751d'
                                }}
                            />
                        </Tooltip>
                    )
                }

                if (params.value === "APPROVED") {
                    return (
                        <Tooltip title="Approved">
                            <TaskAltIcon
                                sx={{
                                    color: '#4d784d'
                                }}
                            />
                        </Tooltip>
                    )
                }

                if (params.value === "MISSING") {
                    return (
                        <Tooltip title="Missing">
                            <HelpOutlineIcon
                                sx={{
                                    color: '#ca1d1b'
                                }}
                            />
                        </Tooltip>
                    )
                }

                if (params.value === "REJECTED") {
                    return (
                        <Tooltip title="Rejected">
                            <ReportProblemIcon
                                sx={{
                                    color: '#ca1d1b'
                                }}
                            />
                        </Tooltip>
                    )
                }
            },
        },
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     headerName: 'Actions',
        //     width: 100,
        //     cellClassName: 'actions',
        //     getActions: ({id}) => {
        //         return [
        //             <GridActionsCellItem
        //                 icon={<EditIcon/>}
        //                 label="Edit"
        //                 className="textPrimary"
        //                 onClick={handleEditClick(id)}
        //                 color="inherit"
        //             />,
        //             <GridActionsCellItem
        //                 icon={<DeleteIcon/>}
        //                 label="Delete"
        //                 onClick={handleDeleteClick(id)}
        //                 color="inherit"
        //             />,
        //         ];
        //     },
        // },
    ];

    const getAutocompleteData = () => {
        axiosInstance.get(`/employees/timesheet-users`, buildRequestHeaders())
            .then(response => {
                setAutocompleteData(response.data);
                dispatch(hideSpinner());
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getPayrollByPeriod = () => axiosInstance.get(`/timesheet/${selected.id}?periodStart=${periodStart}&periodEnd=${periodEnd}`, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            dispatch(hideSpinner());
        });

    const submitOnClick = () => {
        if (dayjs(periodEnd).isBefore(dayjs(periodStart))) {
            dispatch(setShowSnackbar({
                title: "Error!",
                message: "Period start must be before Period end",
                type: SnackbarTypes.ERROR,
            }));

            return;
        }

        getPayrollByPeriod();
    }

    const submitCustomTimelog = () => {
        let snackbarData;

        axiosInstance.post("/timesheet/time-in/custom", {
            employeeId: selected.id,
            timeIn: customLogRef.current.periodStart,
            timeOut: customLogRef.current.periodEnd,
            actualHours: Number.parseInt(customLogRef.current.actualHours),
        }, buildRequestHeaders()).then(response => {
            getPayrollByPeriod();
            snackbarData = {
                message: "Custom timelog added successfully",
                title: "Success!",
                type: SnackbarTypes.SUCCESS,
            }
            setIsModalOpen(false);
        }).catch(error => {
            console.error(error);
            snackbarData = {
                message: `Failed to add custom timelog: ${error.response.data}`,
                title: "Failed!",
                type: SnackbarTypes.ERROR,
            }
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarData));
        })
    };

    const addLogOnClick = () => {
        setIsModalOpen(true);
        setModalData({
            title: 'Add Employee Log',
            content: renderModalContent(),
            affirmativeHandler: () => {submitCustomTimelog()},
            negativeHandler: () => {
                setIsModalOpen(false);
            },
        })
    }

    const renderModalContent = () => {
        return (
            <Box sx={{
                marginTop: '12px'
            }}>
                <Box>
                    <MobileDateTimePicker
                        sx={{
                            width: '200px',
                            marginRight: '8px'
                        }}
                        variant="outlined"
                        label="From"
                        onChange={event => setCustomLog({...customLogRef.current, periodStart: event.$d})}
                        onAccept={event => setCustomLog({...customLogRef.current, periodStart: event.$d})}
                        slotProps={{
                            textField: {
                                size: 'small'
                            }
                        }}
                        // shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                        disableFuture={true}
                    />
                    <MobileDateTimePicker
                        sx={{
                            width: '200px',
                            marginRight: '8px'
                        }}
                        variant="outlined"
                        label="To"
                        onChange={event => setCustomLog({...customLogRef.current, periodEnd: event.$d})}
                        onAccept={event => setCustomLog({...customLogRef.current, periodEnd: event.$d})}
                        slotProps={{
                            textField: {
                                size: 'small'
                            }
                        }}
                        // shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                        disableFuture={true}
                    />
                </Box>
                <Box sx={{
                    marginTop: '8px'
                }}>
                    <Typography className="inputFieldLabel"
                                sx={{fontWeight: '500'}}>
                        Actual Hours
                    </Typography>
                    <TextField
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 24,
                            }
                        }}
                        sx={{width: '100%', marginTop: '8px'}}
                        variant="outlined"
                        onChange={event => setCustomLog({...customLogRef.current, actualHours: event.target.value})}
                        defaultValue="0"
                        type="number"
                        error={formError['actualTime']}
                        helperText={formError['actualTime']}
                        size="small"
                    />
                </Box>
            </Box>
        )
    }

    const periodStartOnChange = (event) => {
        setPeriodStart(dayjs(event.$d).format('MM/DD/YYYY'));
    }

    const periodEndOnChange = (event) => {
        setPeriodEnd(dayjs(event.$d).format('MM/DD/YYYY'));
    }

    const autoCompleteOnChange = (event, value) => {
        setSelected(value);
    }

    const isSubmitDisabled = !periodStart || !periodEnd;

    return (
        <Box>
            <Box sx={{
                height: `100%`,
                width: `calc(100% - ${Styles.DRAWER_WIDTH} - 40px)`,
                display: 'flex',
                flexDirection: 'column',
                marginLeft: `calc(${Styles.DRAWER_WIDTH} + 20px)`,
            }}>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', width: '100%'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            marginTop: '20px',
                            marginBottom: '10px',
                            width: "100%",
                        }}
                    >
                        <FormControl
                            sx={{
                                marginRight: '14px',
                                width: '100%',
                                display: "flex"
                            }}
                        >
                            <Typography className="inputFieldLabel"
                                        sx={{fontWeight: '500'}}>
                                Employee Logs
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '8px',
                                    width: "100%",
                                }}
                            >
                                <Autocomplete
                                    sx={{width: 400, marginRight: '8px',}}
                                    size="small"
                                    options={autoCompleteData}
                                    onChange={autoCompleteOnChange}
                                    autoHighlight
                                    getOptionLabel={(option) => `${option.lastName}, ${option.firstName}`}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            {option.lastName}, {option.firstName}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Name"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <DatePicker
                                    sx={{
                                        width: '200px',
                                        marginRight: '8px'
                                    }}
                                    variant="outlined"
                                    label="From"
                                    onChange={periodStartOnChange}
                                    slotProps={{
                                        textField: {
                                            size: 'small'
                                        }
                                    }}
                                    // shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                                    disableFuture={true}
                                />
                                <DatePicker
                                    sx={{
                                        width: '200px',
                                        marginRight: '8px'
                                    }}
                                    variant="outlined"
                                    label="To"
                                    onChange={periodEndOnChange}
                                    slotProps={{
                                        textField: {
                                            size: 'small'
                                        }
                                    }}
                                    // shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                                    disableFuture={true}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '100px',
                                        height: '40px',
                                    }}
                                    onClick={submitOnClick}
                                    disabled={isSubmitDisabled}
                                >
                                    <Typography
                                        sx={{
                                            padding: '8px',
                                            fontSize: '16px',
                                        }}
                                    >Submit
                                    </Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '120px',
                                        height: '40px',
                                        position: 'absolute',
                                        right: 0,
                                    }}
                                    onClick={addLogOnClick}
                                    disabled={!selected || selected?.length <= 0}
                                >
                                    <Typography
                                        sx={{
                                            padding: '8px',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Add Log
                                    </Typography>
                                </Button>
                            </Box>
                        </FormControl>
                    </Box>
                    <DataGrid
                        rows={rowData}
                        getRowId={(row) => `${row.id}`}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        autoHeight={true}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        disableRowSelectionOnClick
                        // experimentalFeatures={{columnGrouping: true}}
                        // columnGroupingModel={[]}
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                whiteSpace: "normal",
                                lineHeight: "normal"
                            },
                            "& .MuiDataGrid-columnHeader": {
                                // Forced to use important since overriding inline styles
                                height: "unset !important"
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // Forced to use important since overriding inline styles
                                maxHeight: "168px !important"
                            }
                        }}
                    />
                </Box>
            </Box>
            <AlertDialog
                isOpen={isModalOpen}
                content={modalData.content}
                title={modalData.title}
                confirmCallback={modalData.affirmativeHandler}
                cancelCallback={modalData.negativeHandler}
            />
        </Box>
    )
}